import '../Scss/style.scss';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
/* eslint-disable */


import {defineAsyncComponent} from 'vue';
import {Component} from '@vue/runtime-core';
import {Modal} from 'bootstrap';

import createApp, {VueDsCookies} from '@digitalwerk/frontend-muster';
import PageHeader from "./Components/PageHeader.vue";

const NewsList = defineAsyncComponent(() => import('../../../../dw_page_types/Resources/Private/Vue/News/List.vue'));
const FactsItem = defineAsyncComponent(() => import('./Components/FactsItem.vue'));
const TeaserSlider = defineAsyncComponent(() => import('../../../../dw_page_types/Resources/Private/Vue/Teasers/TeaserSlider.vue'));
const UsersList = defineAsyncComponent(() => import('../../../../dw_users/Resources/Private/Vue/Users/List.vue'));
const AjaxForm = defineAsyncComponent(() => import('./Components/AjaxForm.vue'));
const ProfileForm = defineAsyncComponent(() => import('./Form/ProfileForm.vue'));
const DocumentsForm = defineAsyncComponent(() => import('./Form/DocumentsForm.vue'));
const DonateForm = defineAsyncComponent(() => import('./Form/DonateForm.vue'));
const OffersForm = defineAsyncComponent(() => import('./Form/OffersForm.vue'));
import {ElSelect, ElOption, ElDatePicker, ElInputNumber} from 'element-plus';

const projectRootComponent: Component = {
    components: {
        'page-header': PageHeader,
        'news-list': NewsList,
        'teaser-slider': TeaserSlider,
        'facts-item': FactsItem,
        'users-list': UsersList,
        'ajax-form': AjaxForm,
        ElSelect,
        ElOption,
        ElDatePicker,
        ElInputNumber,
        ProfileForm,
        DocumentsForm,
        DonateForm,
        OffersForm,
    },
    provide() {
        return {
            showPassword: this.showPassword,
            lazyLoad: this.lazyLoad,
            scrollOffset: this.scrollOffset,
        }
    },
    methods: {
        showPassword() {
            const togglePasswords = document.querySelectorAll('.icon-password-control');
            togglePasswords.forEach(togglePassword => togglePassword.addEventListener('click', () => {
                let passwordInput = togglePassword.closest('.password-wrapper')?.querySelector('.inputPassword');
                const passwordType = passwordInput?.getAttribute('type') === 'password' ? 'text' : 'password';
                passwordInput?.setAttribute('type', passwordType);
                togglePassword?.classList.toggle('active');
            }));
        },

        mountedHook() {
            if (document.querySelector<HTMLElement>('.ce-page-hero')) {
                document.body.classList.add('with-page-hero');
            }

            this.showPassword();
            document.querySelectorAll('.modal').forEach((item) => new Modal(item));
        },
    },
};

const app = createApp(projectRootComponent);

app.use(VueDsCookies);

app.mount('#page');
