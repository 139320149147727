
import { defineComponent, ref } from "vue";
import { debounce } from "lodash";

const SCROLL_DELTA = 80;

export default defineComponent({
    setup() {
        let isHidden = ref(false);
        let isSticky = ref(false);
        let withPageHero = document.querySelector(".with-page-hero");
        let lastScrollPosition = window.pageYOffset;
        window.addEventListener("scroll", () => {
            if (window.pageYOffset >= 0) {
                if (Math.abs(window.pageYOffset - lastScrollPosition) >= SCROLL_DELTA) {
                    isHidden.value = window.pageYOffset >= lastScrollPosition;
                    lastScrollPosition = window.pageYOffset;
                }
            }
            if (window.pageYOffset >= SCROLL_DELTA) {
                isSticky.value = true;
            } else {
                isSticky.value = false;
            }
        });

        window.addEventListener(
            "resize",
            debounce(() => {
                if (window.innerWidth > 992) {
                    document.body.classList.remove("mobile-nav-opened");
                    document.body.classList.remove("main-sub-nav-opened");
                }
            }, 250)
        );

        function toggleMobileNav() {
            let mobileNav = document.querySelector(".mobile-nav");
            if (mobileNav) {
                document.body.classList.toggle("mobile-nav-opened");
                if (document.body.classList.contains("mobile-nav-opened")) {

                    const activeNavItems: Array<HTMLElement> = [];
                    Array.from<HTMLAnchorElement>(mobileNav.querySelectorAll("a.active")).forEach((link) => {
                        const activeNavItem = link.parentElement;
                        if (activeNavItem && activeNavItem.classList.contains("sub")) {
                            activeNavItems.push(activeNavItem);
                        }
                    });
                    activeNavItems.forEach((item) => {
                        item.classList.add("js-opened");
                        document.body.classList.add("main-sub-nav-opened");
                    });
                } else {
                    Array.from<HTMLAnchorElement>(mobileNav.querySelectorAll(".js-opened")).forEach((item) => {
                        item.classList.remove("js-opened");
                        document.body.classList.remove("main-sub-nav-opened");
                    });
                }
            }
        }

        function toggleSubNav(event: Event) {
            const navItem = (<HTMLElement>event.target).parentElement;
            if (navItem?.classList.contains("sub")) {
                navItem.classList.toggle("js-opened");
                document.body.classList.add("main-sub-nav-opened");
            }
        }

        const toggleMainNav = (event: Event, closeNav: boolean) => {
            const navItem = event.target ? (<HTMLInputElement>event.target).parentElement : null;
            if (navItem) {
                if (closeNav) {
                    document.querySelector(".main-nav-item.js-opened")?.classList.remove("js-opened");
                    document.body.classList.remove("main-sub-nav-opened");
                }
            }
            document.body.classList.remove("main-sub-nav-opened");
        };

        const closeLoginSubNav = () => {
            document.querySelector(".login-nav-item.js-opened")?.classList.remove("js-opened");
            document.body.classList.remove("main-sub-nav-opened");
        };

        return {
            isHidden,
            isSticky,
            toggleMobileNav,
            toggleSubNav,
            toggleMainNav,
            closeLoginSubNav,
        };
    },
});
